import styled from 'styled-components'

export const DefautButton = styled.button`
  width: ${({width}) => width};

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: 769px) {
  }

  @media (max-width: 768px) {
    img {
      width: 30px;
    }
  }
`

export const DefaultImg = styled.img`
  width: ${({width}) => width};
`

export const DefaultInput = styled.input`
  width: ${({width}) => width};
  height: 30px;

  display: block;
  font-size: 16px;
  padding: 0 10px;
  border: 1px solid #000;
  border-radius: 5px;
`

export const DefaultButtonOk = styled.button`
  width: ${({width}) => width};
  height: ${({height}) => height};

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({color}) => color};
  border: none;
  border-radius: 10px;
  cursor: pointer;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
`

export const ContainerLogin = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #460273;
`

export const WarningError = styled.p`
  color: #B90E0A;
  font-weight: bold;
`

export const PageBackground = styled.div`
  /* width: 100%; */
  height: 100%;
  /* min-width: 100vw; */
  min-height: 100vh;

  padding: 30px;

  display: flex;
  justify-content: center;

  background-color: #460273;
`

export const DefaultLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
