export const processMock = [
  {
    id:0,
    serial: '111AAA',
    name: 'Nome do Processo 0',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 1',
    date: '08/08/2023 22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 3,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 4,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 5,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 6,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 7,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 8,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:1,
    serial: '222BBB',
    name: 'Nome do Processo 1',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 2',
    date: '08/08/2023  22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:2,
    serial: '333CCC',
    name: 'Nome do Processo 2',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 3',
    date: '08/08/2023  22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:3,
    serial: '444DDD',
    name: 'Nome do Processo 3',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 1',
    date: '08/08/2023  22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:4,
    serial: '555EEE',
    name: 'Nome do Processo 4',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 2',
    date: '08/08/2023  22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:5,
    serial: '666FFF',
    name: 'Nome do Processo 5',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 3',
    date: '08/08/2023  22:22:22',
    status: 'Finalizado',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:6,
    serial: '777GGG',
    name: 'Nome do Processo 6',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 1',
    date: '08/08/2023  22:22:22',
    status: 'N.F',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  },
  {
    id:7,
    serial: '888III',
    name: 'Nome do Processo 7',
    describe: 'Esse é um processo teste',
    equipament: 'Mini OBC',
    version: '1.1.1',
    responsible: 'Pessoa Fulana 2',
    date: '08/08/2023  22:22:22',
    status: 'N.F',
    tasks: [
        {
            id: 0,
            name: 'Tarefa 1',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        },
        {
            id: 1,
            name: 'Tarefa 2',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'false'
        },
        {
            id: 2,
            name: 'Tarefa 3',
            date: '08/08/2023 22:22:22',
            tecnico: "",
            checked: 'true'
        }
    ]
  }
]