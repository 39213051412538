import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonContainer, ButtonShow, Container, ContainerCB, ErrorMessage, FormContainer, FormHalfContainer, Foto, FotoContainer, Input, Label, LabelCB, Select } from './styles';
import { DefautButton } from '../styles';
import backIcon from '../../assets/icons/rewind-backcom.svg';
import { useNavigate } from 'react-router-dom';
import { makeRequest, makeRequestPhoto } from '../../utils/fetchDb';
import AppContext from '../../context/AppContext';
import eye from '../../assets/icons/eye-black.svg';
import eyeOff from '../../assets/icons/eye-off-black.svg';

export default function DetailsUser({ selectBusinessCard }) {
  const [form, setForm] = useState({
    name: Object.keys(selectBusinessCard).length ? selectBusinessCard.Nome : '',
    user: Object.keys(selectBusinessCard).length ? selectBusinessCard.User : '',
    password: Object.keys(selectBusinessCard).length ? selectBusinessCard.Password : '',
    office: Object.keys(selectBusinessCard).length ? selectBusinessCard.Cargo : '',
    number: Object.keys(selectBusinessCard).length ? selectBusinessCard.Numero : '',
    email: Object.keys(selectBusinessCard).length ? selectBusinessCard.Email : '',
    address: Object.keys(selectBusinessCard).length ? selectBusinessCard.Endereco : '',
    linkedin: Object.keys(selectBusinessCard).length ? selectBusinessCard.Linkedin : '',
    image: Object.keys(selectBusinessCard).length ? selectBusinessCard.Foto : null,
    status: Object.keys(selectBusinessCard).length ? selectBusinessCard.Ativo : '',
    allowed: Object.keys(selectBusinessCard).length ? selectBusinessCard.Allowed : {
      MiniOBC: 0,
      PcAlston: 0,
      QrCode: 0,
      RH: 0
    },
  });
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user, setAttList, hash } = useContext(AppContext);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { value, name, type, files, checked } = event.target;

    if (type === 'file') {
      setForm({
        ...form,
        [name]: files[0],
      });
    } else if (type === 'checkbox') {
      setForm({
        ...form,
        'allowed' : {
          ...form.allowed,
          [name]: checked ? 1 : 0,
        }
      })
    }
    else {
      setForm({
        ...form,
        [name]: value,
      });
    }
    
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleAdd = async () => {
    const fieldErrors = {};

    if (!form.name) fieldErrors.name = 'Nome é obrigatório.';
    if (!form.user) fieldErrors.user = 'Usuário é obrigatório.';
    if (!form.password) fieldErrors.password = 'Senha é obrigatório.';
    if (!form.email) fieldErrors.email = 'E-mail é obrigatório.';
    if (!form.office) fieldErrors.office = 'Cargo é obrigatório.';
    if (!form.number) fieldErrors.number = 'Número é obrigatório.';
    if (!form.address) fieldErrors.address = 'Endereço é obrigatório.';
    if (!form.linkedin) fieldErrors.linkedin = 'Linkedin é obrigatório.';
    if (!form.image) fieldErrors.image = 'Foto é obrigatório.';

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
    } else {
      setErrors({});
      const data =
        {
          Nome: form.name,
          User: form.user,
          Password: form.password,
          Cargo: form.office,
          Numero: form.number,
          Email: form.email,
          Endereco: form.address,
          Linkedin: form.linkedin,
          Ativo: Number(form.status),
          Allowed: {
            MiniOBC: Number(form.allowed.MiniOBC),
            PcAlston: Number(form.allowed.PcAlston),
            QrCode: Number(form.allowed.QrCode),
            RH: Number(form.allowed.RH),
          }
        }
        
        const fetchDBImage = await makeRequestPhoto('UploadPhoto', form.image, form.user, hash);
        
      if (fetchDBImage && (fetchDBImage.response.status === 200)) {
        const fetchDB = await makeRequest('POST', `${isEdit ? `User?User=${selectBusinessCard.User}` : 'User'}`, data, hash);
        if (fetchDB && (fetchDB.response.status === 200)) {
          console.log(`${isEdit ? 'Usuário editado com sucesso' : 'Usuário adicionado com sucesso'}`);
          setAttList(true);
          navigate('/user');
        } else {
          console.log('Error');
        }
      }        
    }
  };

  useEffect(() => {
    if (Object.keys(selectBusinessCard).length > 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [selectBusinessCard])

  useEffect(() => {
    if (user && user.permissao.RH !== 1) navigate('/ferramentas')
  }, [navigate, user, selectBusinessCard, isEdit])
  return (
    <Container>
      <DefautButton style={{ justifyContent: 'start'}} onClick={() => navigate('/user')}>
        <img src={backIcon} alt="backIcon" />
      </DefautButton>
      <FormContainer>
        <FotoContainer>
          <Label htmlFor="image">
            {selectBusinessCard.Foto ? (<Foto src={selectBusinessCard.Foto} alt="Imagem Selecionada" />) : (
              form.image && <Foto src={URL.createObjectURL(form.image)} alt="Imagem Selecionada" />
            )}
            <Input type="file" accept="image/*" name="image" onChange={handleChange} style={{background: 'none'}} />
            {errors.image && <ErrorMessage>{errors.image}</ErrorMessage>}
          </Label>
        </FotoContainer>
        <FormHalfContainer>
          <Label htmlFor="name">
            <p>Nome</p>
            <Input type="text" name="name" value={form.name} placeholder="Digite" onChange={handleChange} />
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </Label>
          <Label htmlFor="user">
            <p>Usuário</p>
            <Input type="text" name="user" value={form.user} placeholder="Digite" onChange={handleChange} />
            {errors.user && <ErrorMessage>{errors.user}</ErrorMessage>}
          </Label>
          <Label htmlFor="password">
            <p>Senha</p>
            <Input type={showPassword ? "text" : "password"} name="password" value={form.password} placeholder="Digite" onChange={handleChange} />
            <ButtonShow onClick={() => setShowPassword(!showPassword)}><img src={showPassword ? eye : eyeOff} alt="Mostrar/ocultar senha" /></ButtonShow>
            {errors.password && <ErrorMessage style={{ marginTop: "-35px"}}>{errors.password}</ErrorMessage>}
          </Label>
          <Label htmlFor="office" style={errors.password ? { marginTop: "0px"} : { marginTop: "-25px"}}>
            <p>Cargo</p>
            <Input type="text" name="office" value={form.office} placeholder="Digite" onChange={handleChange}/>
            {errors.office && <ErrorMessage>{errors.office}</ErrorMessage>}
          </Label>
        </FormHalfContainer>
      </FormContainer>
      <FormContainer>
        <FormHalfContainer>
          <Label htmlFor="linkedin">
            <p>Linkedin</p>
            <Input type="text" name="linkedin" value={form.linkedin} placeholder="Digite" onChange={handleChange}/>
            {errors.linkedin && <ErrorMessage>{errors.linkedin}</ErrorMessage>}
          </Label>
          <Label htmlFor="status">
            <p>Status</p>
            <Select name="status" value={form.status} onChange={handleChange}>
              <option value="" disabled>Selecione</option>
              <option value={1}>Ativo</option>
              <option value={0}>Desligado</option>
            </Select>
            {errors.status && <ErrorMessage>{errors.status}</ErrorMessage>}
          </Label>
          <Label htmlFor="allowed">
            <p>Permissões</p>
          </Label>
          <ContainerCB>
            <LabelCB htmlFor="MiniOBC">
              <input type="checkbox" id="MiniOBC" name="MiniOBC" checked={form.allowed.MiniOBC === 1} onChange={handleChange} />
              MiniOBC
            </LabelCB>
            <LabelCB htmlFor="PcAlston">
              <input type="checkbox" id="PcAlston" name="PcAlston" checked={form.allowed.PcAlston === 1} onChange={handleChange} />
              PC - Alston
            </LabelCB>
          </ContainerCB>
          <ContainerCB>
            <LabelCB htmlFor="QrCode">
              <input type="checkbox" id="QrCode" name="QrCode" checked={form.allowed.QrCode === 1} onChange={handleChange} />
              QRCode
            </LabelCB>
            <LabelCB htmlFor="RH">
              <input type="checkbox" id="RH" name="RH" checked={form.allowed.RH === 1} onChange={handleChange} />
              Recursos Humanos
            </LabelCB>
          </ContainerCB>
        </FormHalfContainer>
        <FormHalfContainer>
          <Label htmlFor="number">
            <p>Número</p>
            <Input type="tel" name="number" value={form.number} placeholder="Digite" onChange={handleChange}/>
            {errors.number && <ErrorMessage>{errors.number}</ErrorMessage>}
          </Label>
          <Label htmlFor="email">
            <p>E-mail</p>
            <Input type="email" name="email" value={form.email} placeholder="Digite" onChange={handleChange} />
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </Label>
          <Label htmlFor="address">
            <p>Endereço</p>
            <Input type="text" name="address" value={form.address} placeholder="Digite" onChange={handleChange}/>
            {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
          </Label>
        </FormHalfContainer>
      </FormContainer>
      <ButtonContainer>
        <Button onClick={handleAdd}>{isEdit ? (
          "Confirmar"
         ) : (
          "Adicionar"
         )}
        </Button>
      </ButtonContainer>
    </Container>
  );
}
