import React, { useContext, useEffect, useState } from 'react';
import { BusinessCardContainer, ProcessContainer } from './style';
import { ButtonsContainer, CRUDContainer } from '../ToolsManager/styles';
import { DefautButton } from '../styles';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/icons/rewind-backcom.svg';
import addIcon from '../../assets/icons/add.svg';
import editIcon from '../../assets/icons/edit.svg';
import removeIcon from '../../assets/icons/trash.svg';
import AppContext from '../../context/AppContext';
import BusinessCardTable from '../Table/BusinessCardTable';
import FindBusinessCard from './FindBusinessCard';
import { makeRequest } from '../../utils/fetchDb';

export default function UserCard() {
  const [filteredProcesses, setFilteredProcesses] = useState([]);

  const { selectBusinessCard, setSelectBusinessCard, businessCardData, setAttList } = useContext(AppContext);

  const columnMapping = {
    Nome: 'Nome',
    Cargo: 'Cargo',
    Ativo: 'Status'
  };

  const navigate = useNavigate();

  const handleEdit = () => {
    if (Object.keys(selectBusinessCard).length > 0) navigate(`/user/${selectBusinessCard.User}`);
  }

  const handleAdd = () => {
    setSelectBusinessCard({})
    navigate(`/user/novo-cartao`);
  }

  const handleRemove = async () => {
    const fetchDB = await makeRequest('DELETE', `User?User=${selectBusinessCard.User}`);
      if (fetchDB && fetchDB.response.status === 200) {
        console.log('Usuário deletado com sucesso');
        setAttList(true);
        navigate('/user');
        // window.location.reload();
      } else {
        console.log('Error');
      }
  }

  useEffect(() => {
    setFilteredProcesses(businessCardData);
  }, [businessCardData]);

  return (
    <BusinessCardContainer>
      <ButtonsContainer>
        <DefautButton onClick={() => navigate('/ferramentas')}>
          <img src={backIcon} alt="backIcon" />
        </DefautButton>
        <CRUDContainer>
          <DefautButton onClick={handleAdd}>
            <img src={addIcon} alt="addIcon" />
          </DefautButton>
          <DefautButton onClick={handleEdit}>
            <img src={editIcon} alt="editIcon" />
          </DefautButton>
          <DefautButton onClick={handleRemove}>
            <img src={removeIcon} alt="removeIcon" />
          </DefautButton>
        </CRUDContainer>
      </ButtonsContainer>
        <FindBusinessCard setFilteredProcesses={setFilteredProcesses} process={businessCardData} />
        <ProcessContainer>
          <BusinessCardTable
            dataArray={filteredProcesses}
            columnMapping={columnMapping}
            selectProcess={selectBusinessCard}
            setSelectProcess={setSelectBusinessCard}
          />
        </ProcessContainer>
    </BusinessCardContainer>
  )
}
