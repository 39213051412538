import React, { useContext } from 'react'
import logoPwt from '../../assets/images/logo-pwt.png'
import AppContext from '../../context/AppContext'
import { useNavigate } from 'react-router-dom';
import { Container, ContainerLogout, Logo, LogoutButton, LogoutImg, Title } from './styles';
import logoutIcon from '../../assets/icons/logout.svg';

export default function Header({ title, user, isShare }) {
  const { setUser, setIsLogged } = useContext(AppContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    setUser('');
    setIsLogged(false);
    localStorage.removeItem('login');
    navigate('/')
  }

  return (
    <Container>
      <Logo src={logoPwt} alt="logoPwt" />
      <div>
        <Title>{title}</Title>
      </div>
      <ContainerLogout>
        <p>{user.user}</p>
        {!isShare && <LogoutButton onClick={handleLogout}><LogoutImg src={logoutIcon} alt="logoutIcon" /></LogoutButton>}
      </ContainerLogout>
    </Container>
  )
}
