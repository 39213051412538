import React, { useContext, useEffect, useRef, useState } from 'react';
import { DefautButton } from '../styles';
import { ContainerBackground, DetailsContainer, DetailsLabel, HeaderContainer, Title } from './styles';
import backIcon from '../../assets/icons/rewind-backcom.svg';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { Ball, EtiquetaButton, LoadingSpinner, PopupContent, PopupOverlay, StyledTable, StyledTd, StyledTh, StyledTr, ToggleButton } from '../Table/styles';
import { openNewWindow } from '../../utils/openWindow';
import saveEtiquetaAsSVG from '../../utils/saveEtiquetaAsSVG';
import QRCode from 'qrcode-generator';
import { makeRequest } from '../../utils/fetchDb';

export default function DetailsProcess({ columnMapping, title, type }) {
  const [isLoading, setIsLoading] = useState('');
  const [selectDate, setSelectDate] = useState('');

  const { selectProcess, setSelectProcess, user, hash, setAttList } = useContext(AppContext);

  const navigate = useNavigate();
  const qrcodeContainer = useRef(null);

  const handleToggle = async (taskIndex) => {
    const currentDateTime = new Date();
    const day = currentDateTime.getDate().toString().padStart(2, '0');
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDateTime.getFullYear();
    const hours = currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    const updatedTasks = selectProcess.tasks.map((task, index) =>
      index === taskIndex
        ? {
            ...task,
            date: task.checked === 'false' ? formattedDateTime : '',
            checked: task.checked === 'true' ? 'false' : 'true'
          }
        : task
    );

    setSelectDate(formattedDateTime);

    setSelectProcess({
      ...selectProcess,
      tasks: updatedTasks,
    });
    
  };

  useEffect(() => {
    const emptyDate = selectProcess.tasks.some((element) => element.date === '');
    if (emptyDate === false) {
      selectProcess.finalDate = selectDate ? selectDate : selectProcess.finalDate;
      selectProcess.status = 'Finalizado';
    } else {
      selectProcess.finalDate = '';
      selectProcess.status = 'N.F';
    };
    const teste = async () => {
      await makeFetch();
    };

    teste();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectProcess])

  const makeFetch = async () => {
    setIsLoading(true);
    if (type === 'vli-montagem-details') {
      const {response} = await makeRequest('POST', `MiniOBC?serial=${selectProcess.serial}`, selectProcess, hash);
      if (response.status === 200) {
        setAttList(true);
      }
    } else if (type === 'vli-pc-alston-details') {
      await makeRequest('POST', `PcAlston?serial=${selectProcess.serial}`, selectProcess, hash);
    }
    setIsLoading(false);
  }

  const handleGenerateLabel = () => {
    const svgString = qrcodeContainer.current.innerHTML;
    saveEtiquetaAsSVG(selectProcess, svgString);
    openNewWindow(selectProcess, '340', '210');
  };

  const generateQRCode = () => {
    const qrcode = QRCode(0, 'H');
    if (selectProcess.equipament === 'Mini OBC') {
      qrcode.addData(`https://www.service.portwell.com.br/MiniOBC?SN=${selectProcess.serial}`);
      // qrcode.addData(`http://192.168.100.7:3000/MiniOBC?SN=${selectProcess.serial}`);
    } else {
      qrcode.addData(`https://www.service.portwell.com.br/PCAlston?SN=${selectProcess.serial}`);
      // qrcode.addData(`http://192.168.100.7:3000/PCAlston?SN=${selectProcess.serial}`);
    }
    qrcode.make();
    qrcodeContainer.current.innerHTML = qrcode.createSvgTag({ cellSize: 4 });
  };

  useEffect(() => {
    if (Object.keys(selectProcess).length === 0 || !selectProcess) {
      return navigate('/');
    }

    generateQRCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, selectProcess]);

  useEffect(() => {
    if (selectProcess.equipament === 'Mini OBC') {
      if (user.permissao.MiniOBC !== 1) navigate('/')
    } else {
      if (user.permissao.PcAlston !== 1) navigate('/')
    }
  }, [navigate, selectProcess, user])

  return (
    <ContainerBackground>
      <HeaderContainer>
        <DefautButton onClick={() => type === 'vli-montagem-details' ? navigate('/vli-montagem') : navigate('/vli-pc-alston') }>
          <img src={backIcon} alt="backIcon" />
        </DefautButton>
        <Title>{`Montagem ${title}`}</Title>
      </HeaderContainer>
      {selectProcess && (
        <>
          <DetailsContainer>
            <DetailsLabel>
              <h4>Equipamento:</h4>
              <p>{selectProcess.equipament}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Nº de Série:</h4>
              <p>{selectProcess.serial}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Versão:</h4>
              <p>{selectProcess.version}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Responsável Técnico:</h4>
              <p>{selectProcess.responsible}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Responsável pelo teste:</h4>
              <p>{selectProcess.responsibleTeste}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Data de finalização dos testes:</h4>
              <p>{selectProcess.finalDate}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>QR-Code:</h4>
              <div ref={qrcodeContainer}></div>
            </DetailsLabel>
          </DetailsContainer>
          <StyledTable>
            <thead>
              <tr>
                {Object.keys(columnMapping).map((column, index) => (
                  <StyledTh key={index}>{columnMapping[column]}</StyledTh>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectProcess.tasks && selectProcess.tasks.map((item, rowIndex) => (
                <StyledTr key={rowIndex}>
                  {Object.keys(columnMapping).map((column, columnIndex) => (
                    <StyledTd key={columnIndex}>
                      {column === 'checked' ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <ToggleButton
                            checked={item[column] === 'true'}
                            onClick={() => handleToggle(rowIndex)}
                          >
                            <Ball checked={item[column] === 'true'} />
                          </ToggleButton>
                        </div>
                      ) : (
                        item[column]
                      )}
                    </StyledTd>
                  ))}
                </StyledTr>
              ))}
            </tbody>
          </StyledTable>
        </>
      )}
      <EtiquetaButton onClick={handleGenerateLabel}>
        Gerar Etiqueta
      </EtiquetaButton>
      {isLoading && (
        <PopupOverlay>
          <PopupContent>
            <LoadingSpinner />
            <p>Carregando...</p>
          </PopupContent>
        </PopupOverlay>
      )}
    </ContainerBackground>
  );
}
