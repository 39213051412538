import React, { useContext, useEffect } from 'react'
import Header from '../components/header/Header'
import AppContext from '../context/AppContext'
import { useNavigate } from 'react-router-dom';
import { PageBackground } from '../components/styles';
import Manager from '../components/ToolsManager/Manager';

export default function Ferramentas() {
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();

  const handleLogin = () => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login))
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    handleLogin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, setUser]);

  return (
    <PageBackground>
      <Header title="Ferramentas" user={user} />
      { user && <Manager user={user} /> }
    </PageBackground>
  )
}
