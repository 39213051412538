import React, { useContext, useState } from 'react';
import AppContext from '../../context/AppContext';
import { ButtonBuscar, ButtonsExportContainer, Container, ExportButton, FormContainer, InputForm, LabelForm } from './style';
import exportIcon from '../../assets/icons/file-export-.svg';
import { CSVLink } from 'react-csv';

export default function FindQrcode({ setFilteredProcesses }) {
  const [form, setForm] = useState({
    name: '',
    link: '',
    date: ''
  });

  const { qrcodeList } = useContext(AppContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  const handleClick = () => {
    const findSerial = qrcodeList.filter((element) => {
      const nameMatch = form.name === '' || element.name.includes(form.name);
      const linkMatch = form.link === '' || element.link.includes(form.link);
      const dateMatch = form.date === '' || element.date.includes(form.date);

      return nameMatch && linkMatch && dateMatch;
    });
    if (findSerial.length > 0) {
      setFilteredProcesses(findSerial);
    } else {
      setFilteredProcesses([]);
    }
  }

  const csvData = qrcodeList.map((item) => ({
    id: item.id,
    name: item.name,
    link: item.link,
    date: item.date
  }));

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Nome', key: 'name' },
    { label: 'Link', key: 'link' },
    { label: 'Data', key: 'date' }
  ];

  return (
    <Container>
      <FormContainer>
        <LabelForm htmlFor="name">
          Nome
          <InputForm
            type="text"
            name="name"
            id="name"
            placeholder="Digite"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="link">
          Link
          <InputForm
            type="text"
            name="link"
            id="link"
            placeholder="Digite"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="date">
          Data/Hora
          <InputForm
            type="datetime-local"
            name="date"
            id="date"
            placeholder="Selecione a data e hora"
            onChange={handleChange}
          />
        </LabelForm>
      </FormContainer>
      <ButtonsExportContainer>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <ButtonBuscar onClick={() => handleClick()}>Buscar</ButtonBuscar>
        </div>
        <ExportButton>
          <CSVLink data={csvData} headers={csvHeaders} filename="VLIMontagem.csv" >
            <img src={exportIcon} alt="exportIcon" />
          </CSVLink>
        </ExportButton>
      </ButtonsExportContainer>
    </Container>
  )
}
