import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import { PageBackground } from '../components/styles';
import { processMock } from '../mocks/process';
import ShareProcess from '../components/DetailsProcess/ShareProcess';

export default function SharePage() {
  const [process, setProcess] = useState([]);
  const [selectProcess, setSelectProcess] = useState([])
  const [params, setParams] = useState({
    title: '',
    titleDetails: '',
    link: ''
  })

  const columnMapping = {
    id: 'Item',
    name: 'Tarefa',
    date: 'Data/Hora',
    tecnico: 'Técnico'
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const snParam = urlParams.get('SN');

    if (snParam) {
      const filterProcess = process.filter((element) => (element.serial === snParam) && element)
      setSelectProcess(filterProcess)
    }

    setProcess(processMock);
  }, [process]);

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const type = pathSegments[1];

    if (type === 'MiniOBC') {
      setParams({title: 'VLI - MINI OBC', titleDetails: 'MINI OBC', link: 'MiniOBC'});
    } else if (type === 'PCAlston') {
      setParams({title: 'VLI - PC Alston', titleDetails: 'PC Alston', link: 'PCAlston'})
    }
  }, [])

  return (
    <PageBackground>
      <Header title={`${params.title}`} user={{user: ""}} isShare={true} />
      <ShareProcess columnMapping={columnMapping} selectProcess={selectProcess} title={params.titleDetails} link={params.link} />
    </PageBackground>
  );
}
