import React, { useContext, useEffect, useState } from 'react';
import FindProcess from '../components/Forms/FindProcess';
import Header from '../components/header/Header';
import AppContext from '../context/AppContext';
import { DefautButton, PageBackground } from '../components/styles';
import { FindSerialBackground, Popup, PopupContent, ProcessContainer } from '../components/Forms/style';
import { useNavigate } from 'react-router-dom';
import backIcon from '../assets/icons/rewind-backcom.svg';
import addIcon from '../assets/icons/add.svg';
import editIcon from '../assets/icons/edit.svg';
import removeIcon from '../assets/icons/trash.svg';
import { ButtonsContainer, CRUDContainer } from '../components/ToolsManager/styles';
import GenerateTable from '../components/Table/GenerateTable';
import { makeRequest } from '../utils/fetchDb';

export default function PcAlston() {

  const { user, setUser, selectProcess, setSelectProcess, pcAlstonData, hash, setAttList } = useContext(AppContext);
  const navigate = useNavigate();

  const [filteredProcesses, setFilteredProcesses] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const columnMapping = {
    serial: 'Serial',
    date: 'Data/Hora',
    responsible: 'Responsável',
    status: 'Status'
  };

  const handleEdit = () => {
    if (Object.keys(selectProcess).length > 0) navigate(`/vli-pc-alston-details/${selectProcess.id}`);
  }

  const handleAdd = () => {
    setSelectProcess({})
    if (pcAlstonData.length > 0) {
      navigate(`/vli-pc-alston-details/add/${pcAlstonData[(pcAlstonData.length -1)].id + 1}`)
    } else {
      navigate(`/vli-pc-alston-details/add/0`)
    }
  }

  const handleRemove = async () => {
    const { response } = await makeRequest('DELETE', `PcAlston?serial=${selectProcess.serial}`, null, hash)
    if (response.ok) {
      setAttList(true);
      setShowConfirmationPopup(false)
    };
  }

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    setFilteredProcesses(pcAlstonData);
  }, [pcAlstonData]);

  useEffect(() => {
    if (!user || user.permissao.PcAlston !== 1) navigate('/ferramentas')
  }, [navigate, user])

  return (
    <PageBackground>
      <Header title="VLI - PC Alston" user={user} />
      <FindSerialBackground>
        <ButtonsContainer>
          <DefautButton onClick={() => navigate('/ferramentas')}>
            <img src={backIcon} alt="backIcon" />
          </DefautButton>
          <CRUDContainer>
            <DefautButton onClick={handleAdd} >
              <img src={addIcon} alt="addIcon" />
            </DefautButton>
            <DefautButton onClick={handleEdit} >
              <img src={editIcon} alt="editIcon" />
            </DefautButton>
            <DefautButton onClick={() => setShowConfirmationPopup(true)} >
              <img src={removeIcon} alt="removeIcon" />
            </DefautButton>
          </CRUDContainer>
        </ButtonsContainer>
        <FindProcess setFilteredProcesses={setFilteredProcesses} />
        <ProcessContainer>
          <GenerateTable
            dataArray={filteredProcesses}
            columnMapping={columnMapping}
            selectProcess={selectProcess}
            setSelectProcess={setSelectProcess}
          />
        </ProcessContainer>
      </FindSerialBackground>
      {(showConfirmationPopup && selectProcess) && (
        <Popup>
          <PopupContent>
            <p>Tem certeza de que deseja excluir este conteúdo?</p>
            <button onClick={handleRemove}>Confirmar</button>
            <button onClick={() => setShowConfirmationPopup(false)}>Cancelar</button>
          </PopupContent>
        </Popup>
      )}
    </PageBackground>
  );
}
