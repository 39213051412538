import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BusinessCardContainer } from './style';
import { DefautButton } from '../styles';
import { Button, ButtonContainer, ErrorMessage, FormContainer, FormHalfContainer, Input, Label, TextArea } from '../DetailsUser/styles';
import backIcon from '../../assets/icons/rewind-backcom.svg';
import { makeRequest } from '../../utils/fetchDb';
import AppContext from '../../context/AppContext';
import { formatDate } from '../../utils/formatDate';

export default function AlstonObc({ namePage }) {
  const [form, setForm] = useState({
    serial: '',
    describe: '',
    equipament: namePage === 'alston' ? "Pc Alston" : "Mini OBC",
    version: '',
    responsible: '' || 'Armindo',
    date: '',
    status: 'N.F',
    finalDate: '',
    responsibleTeste: '',
  });

  const tasks = [
    {
      id: 0,
      name: 'Verificar funcionamento da tela do OBC',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 1,
      name: 'Verificar estado da carcaça',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 2,
      name: 'Realizar teste de comunicação porta ethernet',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 3,
      name: 'Realizar teste em todas as portas seriais de comunicação',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 4,
      name: 'Realizar teste do conector 4 pinos teclado',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 5,
      name: 'Realizar teste de acionamento do buzzer',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 6,
      name: 'Realizar teste das portas USB',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 7,
      name: 'Realizar teste de comunicação IRVA',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 8,
      name: 'Realizar teste de penalização',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 9,
      name: 'Realizar teste de chamada de GIGA DD',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 10,
      name: 'Realizar teste de penalização pelo DTMF',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
    {
      id: 11,
      name: 'Realizar teste SISVEM',
      date: '',
      tecnico: `${form.responsibleTeste || 'Armindo'}`,
      checked: 'false'
    },
  ]

  const [errors, setErrors] = useState({});

  const { hash } = useContext(AppContext);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { value, name } = event.target;

    setForm({
      ...form,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleAdd = async () => {
    const fieldErrors = {};

    if (!form.serial) fieldErrors.serial = 'Serial é obrigatório.';
    if (!form.describe) fieldErrors.describe = 'Descrição é obrigatória.';
    if (!form.equipament) fieldErrors.equipament = 'Equipamento é obrigatório.';
    if (!form.version) fieldErrors.version = 'Versão é obrigatória.';
    if (!form.responsible) fieldErrors.responsible = 'Responsável é obrigatório.';
    if (!form.date) fieldErrors.date = 'Data é obrigatória.';
    if (!form.responsibleTeste) fieldErrors.responsibleTeste = 'Responsável é obrigatória.';

    setErrors(fieldErrors);

    if (Object.keys(fieldErrors).length === 0) {
      if (namePage === "alston") {
        const { response } = await makeRequest('POST', 'PcAlston', { ...form, date: formatDate(form.date), tasks: tasks}, hash);
        if (response.ok) {
          navigate('/vli-pc-alston');
        }
      } else {
        const { response } = await makeRequest('POST', 'MiniOBC', { ...form, date: formatDate(form.date), tasks: tasks}, hash);
        if (response.ok) {
          navigate('/vli-montagem');
        }
      }

      setForm({
        serial: '',
        describe: '',
        equipament: '',
        version: '',
        responsible: 'Armindo',
        responsibleTeste: '',
        date: '',
      });
    }
  };

  return (
    <BusinessCardContainer>
      <div style={{ width: '100%'}}>
        <DefautButton onClick={() => navigate(namePage === 'alston' ? '/vli-pc-alston' : '/vli-montagem')}>
          <img src={backIcon} alt="backIcon" />
        </DefautButton>
        <FormContainer style={{ marginTop: '15px'}}>
          <FormHalfContainer>
            <Label htmlFor="serial">
              <p>Serial</p>
              <Input type="text" name="serial" value={form.serial} placeholder='Digite' onChange={handleChange}/>
              {errors.serial && <ErrorMessage>{errors.serial}</ErrorMessage>}
            </Label>
            <Label htmlFor="date">
              <p>Data</p>
              <Input type="datetime-local" name="date" value={form.date} placeholder='Digite' onChange={handleChange}/>
              {errors.date && <ErrorMessage>{errors.date}</ErrorMessage>}
            </Label>
            <Label htmlFor="equipament">
              <p>Equipamento</p>
              <Input type="text" name="equipament" value={form.equipament} disabled />
            </Label>
          </FormHalfContainer>
          <FormHalfContainer>
            <Label htmlFor="version">
              <p>Versão</p>
              <Input type="text" name="version" value={form.version} placeholder='Digite' onChange={handleChange}/>
              {errors.version && <ErrorMessage>{errors.version}</ErrorMessage>}
            </Label>
            <Label htmlFor="responsible">
              <p>Responsável técnico</p>
              <Input type="text" name="responsible" value={form.responsible} placeholder='Digite' onChange={handleChange}/>
              {errors.responsible && <ErrorMessage>{errors.responsible}</ErrorMessage>}
            </Label>
            <Label htmlFor="responsibleTeste">
              <p>Responsável pelos testes</p>
              <Input type="text" name="responsibleTeste" value={form.responsibleTeste} placeholder='Digite' onChange={handleChange}/>
              {errors.responsibleTeste && <ErrorMessage>{errors.responsibleTeste}</ErrorMessage>}
            </Label>
          </FormHalfContainer>
        </FormContainer>
        <Label htmlFor="describe">
          <p>Descrição</p>
          <TextArea type="text" rows="4" maxLength={255} name="describe" value={form.describe} placeholder='Digite' onChange={handleChange}/>
          {errors.describe && <ErrorMessage>{errors.describe}</ErrorMessage>}
        </Label>
        <ButtonContainer style={{ marginTop: '30px'}}>
          <Button onClick={handleAdd}>
            Adicionar
          </Button>
        </ButtonContainer>
      </div>
    </BusinessCardContainer>
  )
}
