import styled from 'styled-components';

export const ContainerForm = styled.form`
  width: 80%;
  height: 50%;
  max-width: 400px;
  height: 320px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 20px;

  border: 1px solid #ccc;
  border-radius: 35px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: #fff;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: ${({ width }) => width};
  height: 30px;

  border: 1px solid #000;
  border-radius: 5px;

  ::placeholder {
    color: #000;
  }
`;

export const Input = styled.input`
  width: 100%;

  display: block;
  font-size: 16px;
  font-weight: bold;
  border: none;

  padding: 0 10px;
`;

export const FindSerialContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    top: 25%;
  }

  @media (max-width: 768px) {
    top: 20%;
  }
`

export const BackButtonContainer = styled.div`
  margin-left: 20px;

  width: 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    position: relative;
  }

  @media (max-width: 768px) {
    position: relative;
  }
`

export const BackButton = styled.button`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  
  border: none;
  background: none;
  cursor: pointer;

  img {
    width: 40px;
  }
`

export const FindSerialForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  width: 100%;
  gap: 30px;
`

export const FindSerialBackground = styled.div`
  max-width: 772px;
  margin-top: 80px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  border: 1px solid #000;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: #fff;

  gap: 16px;
  padding: 24px 33px;

  @media (min-width: 769px) {
    width: 100%;
    height: calc(100% - 80px);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 85%;

    overflow: auto;
  }
`

export const ProcessContainer = styled.div`
  width: 100%;
  height: 100%;

  margin: 30px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  @media (min-width: 769px) {
    overflow: auto;
  }

  @media (max-width: 768px) {
  }
`

export const Process = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;

  border: 1px solid #ccc;
  border-radius: 35px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: #ccc;

  cursor: pointer;

  @media (min-width: 769px) {
    width: 25%;
    height: 35%;

    justify-content: space-around;
  }

  @media (max-width: 768px) {
    width: 40%;
    height: 40%;
    
    justify-content: center;

    gap: 40px;
  }
`

export const TitleProcess = styled.h4`
  color: #460273;
  font-size: 16px;
  font-weight: bold;
`

export const ContentProcess = styled.p`
  color: #0D0D0D;
  font-size: 15px;
  font-weight: normal;
`

export const ErroMessage = styled.p`
  position: fixed;

  top: 60%;
  font-weight: bold;
  color: #A91B0D;
`

export const DetailsContainer = styled.div`
  width: 100%;
  height: 70%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 80px;

  border: 1px solid #ccc;
  border-radius: 35px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: white;

  overflow: auto;

  @media (min-width: 769px) {
    
  }

  @media (max-width: 768px) {
    
  }
`

export const DetailsHeader = styled.header`
  width: 100%;
  height: 20%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  color: #0D0D0D;
`

export const DetailsHeaderContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DetailsTitle = styled.h3`
  font-size: 26px;
  font-weight: bold;
`

export const DetailsStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;

  @media (min-width: 769px) {
    flex-direction: row;

    gap: 90px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    gap: 30px;
  }
`

export const DetailsStatusSlice = styled.div`
  display: flex;

  gap: 30px;

  @media (min-width: 769px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`

export const DetailsLabel = styled.label`
  width: 100%;
  display: flex;
  justify-content: space-between;

  gap: 20px;

  /* text-align: center; */
  font-weight: bold;

  p {
    font-weight: normal;
    text-align: center;
  }
`

export const DetailsTable = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
`

export const TableContainer = styled.div`
margin-top: 20px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 0 0 15px 0;
`;

export const TableData = styled.td`
  padding: 8px;
  text-align: center;
`;

export const Checkbox = styled.input`
  margin: 0;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

export const LabelForm = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 5px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 769px) {
    
  }

  @media (max-width: 768px) {
    width: 100%;
  }
` 

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: 10px;

  padding: 0 0 36px 0;
`

export const Container = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 60px;
  }
`

export const InputForm = styled.input`
  height: 28px;

  border: none;
  border-radius: 8px;
  background: #D9D9D9;

  padding: 0 15px;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 769px) {
    width: ${({width}) => width};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const SelectForm = styled.select`
  height: 28px;

  border: none;
  border-radius: 8px;
  background: #D9D9D9;

  padding: 0 15px;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 769px) {
    
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ButtonBuscar = styled.button`
  display: flex;
  padding: 5px 17px 4px 18px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #000;
  background: #FFF;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;

  @media (max-width: 768px) {
    margin-left: 40px;
  }
`

export const ButtonsExportContainer = styled.div`
  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ExportButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const BusinessCardContainer = styled.div`
  max-width: 772px;
  margin-top: 80px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  border: 1px solid #000;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: #fff;

  gap: 16px;
  padding: 24px 33px;

  @media (min-width: 769px) {
    width: 100%;
    height: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 85%;

    overflow: auto;
  }
`

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const PopupContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;

  p {
    padding-bottom: 20px;
  }
  
  button {
    margin: 10px;
    padding: 10px 20px;

    border-radius: 6px;
    border: 1px solid #000;
    background: #FFF;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;

    cursor: pointer;
  }
`
