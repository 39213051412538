import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../context/AppContext';
import { PageBackground } from '../components/styles';
import Header from '../components/header/Header';
import DetailsBusinessCard from '../components/DetailsUser/DetailsUser';

export default function SelectUser() {
  const { user, setUser, selectBusinessCard } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    if (!selectBusinessCard) {
      navigate('/ferramentas');
    }
  }, [navigate, selectBusinessCard]);

  return (
    <PageBackground>
      <Header title={"Colaboradores"} user={user} />
      <DetailsBusinessCard selectBusinessCard={selectBusinessCard} />
    </PageBackground>
  )
}
