// openWindow.js
import ReactDOM from 'react-dom';
import Etiqueta from '../components/DetailsProcess/Etiqueta';

export function openNewWindow(selectProcess, width, height) {

  const windowFeatures = `width=${width},height=${height}`;
  const newWin = window.open('', '_blank', windowFeatures);

  if (newWin) {
    newWin.document.write('<html><body>');
    newWin.document.write('<div id="root"></div>'); // Div para montar o aplicativo React
    newWin.document.write('</body></html>');
    newWin.document.close();

    // Renderize o componente Etiqueta na nova janela
    const newWinRoot = newWin.document.getElementById('root');
    ReactDOM.render(<Etiqueta selectProcess={selectProcess} />, newWinRoot);
  }
}
