export const qrcodeMock = [
  {
    id: 0,
    name: 'link',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 1',
    link: 'https://service.portwell.com.br/MiniOBC?SN=111AAA'
  },
  {
    id: 1,
    name: 'link 1',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 2',
    link: 'https://service.portwell.com.br/MiniOBC?SN=222BBB'
  },
  {
    id: 2,
    name: 'link 2',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 3',
    link: 'https://service.portwell.com.br/MiniOBC?SN=333CCC'
  },
  {
    id: 3,
    name: 'link 3',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 1',
    link: 'https://service.portwell.com.br/MiniOBC?SN=444DDD'
  },
  {
    id: 4,
    name: 'link 4',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 2',
    link: 'https://service.portwell.com.br/MiniOBC?SN=555EEE'
  },
  {
    id: 5,
    name: 'link 5',
    date: '08/08/2023 22:22:22',
    responsible: 'Pessoa Fulana 2',
    link: 'https://service.portwell.com.br/MiniOBC?SN=666FFF'
  },
]