import React from 'react';
import QRCodeGenerator from '../../utils/QRCodeGenerator';
import logoPtw from '../../assets/images/logo-pwt-black.svg';

export default function Etiqueta({ selectProcess }) {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      background: '#FFF',
    }}>
      <img src={logoPtw} alt="logoPtw" style={{width: '320px'}}/>
      <div style={{
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      
        gap: '4px',
      }}>
        <div style={{
          width: '60%',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',

          gap: '10px',
        }}>
          <div style={{
              width: '100%',
            
              background: '#FFF',
            
              color: '#000',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              textAlign: 'start'
            }}>
            <div style={{
              width: '100%',

              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            
              background: '#FFF',
            
              color: '#000',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              textAlign: 'start'
            }}>{`
              contato@portwell.com.br
            `}</div>
            <div style={{
              width: '100%',

              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            
              background: '#FFF',
            
              color: '#000',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              textAlign: 'start'
            }}>{`
              (41) 3121-7200
            `}</div>
          </div>
          <div style={{
            width: '100%',

            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',

            background: '#FFF',
          
            color: '#000',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'start'
        }}>{`Modelo: ${selectProcess.equipament}`}</div>
          <div style={{
            width: '100%',

            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',

            background: '#FFF',
          
            color: '#000',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'start'
          }}>{`Nº de série: ${selectProcess.serial}`}</div>
        </div>
        
        <QRCodeGenerator text={`https://qrcode.portwell.com.br/MiniOBC?SN=${selectProcess.serial}`} cellSize={2.5} />
      </div>
    </div>
  );
}
