import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (min-width: 769px) {
  }

  @media (max-width: 768px) {
    td {
      font-size: 12px;
      font-weight: 600;
    }

    th {
      font-size: 20px;
    }
  }
`

export const StyledTh = styled.th`
  padding: 8px;
  text-align: center;
`

export const StyledTd = styled.td`
  padding: 8px;
  
  text-align: center;
  color: ${({item}) => (
    (item === 'Finalizado' || item === 1) ? (
      '#00B94A'
    ) : (
      item === 'N.F' || item === 0 ? (
        '#DB0000'
      ) : (
        '#000'
      )
    )
  )};
  
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 769px) {
    max-width: 200px;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledTr = styled.tr`
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected-row {
    border-radius: 8px;
    border: 1px solid #4C0074;
  }
`

export const ToggleButton = styled.button`
  width: 72px;
  height: 38px;

  padding: 5px;
  background-color: ${({ checked }) => (checked ? '#00B94A' : '#DB0000')};
  cursor: pointer;
  display: flex;
  align-items: center;

  border-radius: 22px;
  border: 4px solid #000;
`

export const Ball = styled.div`
  width: 28px;
  height: 28px;

  border-radius: 50%;
  border: 1px solid black;
  background-color: #D9D9D9;
  stroke-width: 1px;
  stroke: #000;

  margin-right: 6px;
  transition: transform 0.3s, background-color 0.3s;
  transform: translateX(${({ checked }) => (checked ? '27px' : '0')});
`

export const EtiquetaButton = styled.a`
  display: flex;
  padding: 12px 27px 12px 26px;
  justify-content: center;
  align-items: center;

  margin: 30px;

  border-radius: 6px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;

  cursor: pointer;
`
export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

export const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
`

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-bottom: 20px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
