import styled from 'styled-components'

export const Container = styled.header`
  width: calc(100% - 60px);
  max-width: 772px;
  height: 60px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  background-color: white;
  border-bottom: 1px solid #ccc;
  border-radius: 28px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);

  @media (min-width: 769px) {
    top: 32px;
  }

  @media (max-width: 768px) {
    top: 12px;
  }
`

export const Title = styled.title`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  font-weight: 700;
  text-align: center;
  line-height: normal;

  @media (min-width: 769px) {
    width: 100%;
    height: 100%;
    
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

export const ContainerLogout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  p {
    width: 100%;
    padding-right: 10px;
    text-align: center;
  }
  @media (min-width: 769px) {
    height: 100%;

    gap: 15px;

    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 20%;
    height: 100%;
  }
`

export const LogoutButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  width: 100%;
  height: 100%;
`

export const LogoutImg = styled.img`
  width:100%;
  height: 100%;

  min-height: 25px;
`

export const Logo = styled.img`
  width: 15%;
`