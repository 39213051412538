import { useState, useMemo, useEffect } from 'react';
import AppContext from '../context/AppContext';
// import { processMock } from '../mocks/process';
import { qrcodeMock } from '../mocks/qrcode';
import { makeRequest } from '../utils/fetchDb';
import { useNavigate } from 'react-router-dom';

export default function AppProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [miniOBCData, setMiniOBCData] = useState([]);
  const [pcAlstonData, setPcAlstonData] = useState([]);
  const [selectProcess, setSelectProcess] = useState({});
  const [selectBusinessCard, setSelectBusinessCard] = useState({});
  const [qrcodeList, setQrcodeList] = useState([]);
  const [selectQrcode, setSelectQrcode] = useState({});
  const [businessCardData, setBusinessCardData] = useState([]);
  const [attList, setAttList] = useState(false);
  const [hash, setHash] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setAttList(false);
    setQrcodeList(qrcodeMock);

    const fetchDBPcAlston = async () => {
      const { response, responseData } = await makeRequest('GET', 'PcAlston', null, hash);
      if (response.ok) {
        setPcAlstonData(responseData);
      } else {
        console.error('Erro na requisição:', responseData);
        navigate('/');
      }
    }

    const fetchDBOBC = async () => {
      const { response, responseData } = await makeRequest('GET', 'MiniOBC', null, hash);
      if (response.ok) {
        setMiniOBCData(responseData);
      } else {
        console.error('Erro na requisição:', responseData);
        navigate('/');
      }
    }

    const fetchDBCV = async () => {
      const { response, responseData } = await makeRequest('GET', 'User', null, hash);

      if (response.ok) {
        // A resposta foi bem-sucedida, os dados estão em responseData
        setBusinessCardData(responseData);
      } else {
        // A resposta da API não foi bem-sucedida, você pode tratar o erro aqui
        console.error('Erro na requisição:', responseData);
        navigate('/');
      }
    };
    
    fetchDBOBC();
    fetchDBCV();
    fetchDBPcAlston();
  }, [isLogged, attList, hash, navigate])

  const context = useMemo(
    () => ({
      loading, setLoading,
      user, setUser,
      selectProcess, setSelectProcess,
      miniOBCData, setMiniOBCData,
      qrcodeList, setQrcodeList,
      selectQrcode, setSelectQrcode,
      businessCardData, setBusinessCardData,
      selectBusinessCard, setSelectBusinessCard,
      users, setUsers,
      isLogged, setIsLogged,
      attList, setAttList,
      hash, setHash,
      pcAlstonData, setPcAlstonData,
    }),
    [
      loading, setLoading,
      user, setUser,
      selectProcess, setSelectProcess,
      miniOBCData, setMiniOBCData,
      qrcodeList, setQrcodeList,
      selectQrcode, setSelectQrcode,
      businessCardData, setBusinessCardData,
      selectBusinessCard, setSelectBusinessCard,
      users, setUsers,
      isLogged, setIsLogged,
      attList, setAttList,
      hash, setHash,
      pcAlstonData, setPcAlstonData,
    ]
  )

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
