import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DetailsQrcode from '../components/DetailsQrcode/DetailsQrcode';
import AppContext from '../context/AppContext';
import { PageBackground } from '../components/styles';
import Header from '../components/header/Header';

export default function SelectedQrcode() {
  const { user, setUser, selectQrcode } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    if (!selectQrcode) {
      navigate('/ferramentas');
    }
  }, [navigate, selectQrcode]);

  return (
    <PageBackground>
      <Header title={"Gerador QRCode"} user={user} />
      <DetailsQrcode selectQrcode={selectQrcode} />
    </PageBackground>
  )
}
