import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 772px;
  height: 100%;

  margin-top: 80px;

  display: flex;
  flex-direction: column;

  padding: 24px 33px;
  gap: 7px;

  border-radius: 28px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 769px) {
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`

export const FormContainer = styled.div`
  width: 100%;

  @media (min-width: 769px) {
    display: flex;
    justify-content: space-between;
    padding: 0 35px 10px 35px;

  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 10px;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;

  @media (min-width: 769px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Input = styled.input`
  width: 307px;
  height: 28px;
  flex-shrink: 0;

  padding: 5px 15px;

  border-radius: 8px;
  border: none;
  background: #D9D9D9;

  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const TextArea = styled.textarea`
  flex-shrink: 0;

  padding: 10px 15px;

  border-radius: 8px;
  border: none;
  background: #D9D9D9;

  font-weight: bold;

  resize: none;

  @media (min-width: 769px) {
    width: 640px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Select = styled.select`
  width: 307px;
  height: 28px;
  flex-shrink: 0;

  padding: 5px 15px;

  border-radius: 8px;
  border: none;
  background: #D9D9D9;

  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (min-width: 769px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ErrorMessage = styled.p`
  color: #DB0000;
  font-size: 12px;
`

export const FotoContainer = styled.div`
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;

    margin-bottom: 15px;
  }
`

export const Foto = styled.img`
  /* width: 150px; */
  height: 150px;

  margin: 20px 0;
`

export const FormHalfContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;

    margin-top: 15px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
`

export const Button = styled.button`
  display: flex;
  padding: 5px 17px 4px 18px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #000;
  background: #FFF;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
`

export const ContainerCB = styled.div`
  display: flex;
  align-items: center;

  gap: 20px;
`

export const LabelCB = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  
  gap: 10px;

  cursor: pointer;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
`

export const ButtonShow = styled.button`
  position: relative;
  top: -35px;
  left: 100px;

  background: none;
  border: none;
  cursor: pointer;

  img {
    opacity: 30%;
    width: 25px;
  }

  img:hover{
    opacity: 100%;
  }

  @media (max-width: 768px) {
    left: 160px;
  }
`
