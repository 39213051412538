import React, { useState } from 'react';
import { ButtonBuscar, ButtonsExportContainer, Container, FormContainer, InputForm, LabelForm, SelectForm } from './style';

export default function FindBusinessCard({ setFilteredProcesses, process }) {
  const [form, setForm] = useState({
    name: '',
    office: '',
    status: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  const handleClick = () => {
    const findSerial = process.filter((element) => {
      const serialMatch = form.name === '' || element.Nome.toUpperCase().includes(form.name.toUpperCase());
      const initialDateMatch = form.office === '' || element.Cargo.toUpperCase().includes(form.office.toUpperCase());
      const statusMatch = form.status === '' || element.Ativo === form.status;

      return serialMatch && initialDateMatch && statusMatch;
    });
    if (findSerial.length > 0) {
      setFilteredProcesses(findSerial);
    } else {
      setFilteredProcesses([]);
    }
  }

  return (
    <Container>
      <FormContainer>
        <LabelForm htmlFor="name">
          Nome
          <InputForm
            type="text"
            name="name"
            id="name"
            placeholder="Digite"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="office">
          Cargo
          <InputForm
            type="text"
            name="office"
            id="office"
            placeholder="Digite"
            onChange={handleChange}
            width="250px"
          />
        </LabelForm>
        <LabelForm htmlFor="status">
          Status
          <SelectForm
            name="status"
            id="status"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="" disabled>Selecione</option>
            <option value={0}>Desligado</option>
            <option value={1}>Ativo</option>
          </SelectForm>
        </LabelForm>
      </FormContainer>
      <ButtonsExportContainer>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <ButtonBuscar onClick={() => handleClick()}>Buscar</ButtonBuscar>
        </div>
      </ButtonsExportContainer>
    </Container>
  )
}
