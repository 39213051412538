export const makeRequest = async (method, endPoint, data = null, hash = null) => {
  try {
    // const url = `http://localhost:5001/${endPoint}`
    const url = `https://mech.portwell.com.br/${endPoint}`

    const headers = {
      'Content-Type': 'application/json',
    }

    if (hash !== null) {
      headers['Authorization'] = `${hash}`;
    }

    const requestOptions = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    }

    const response = await fetch(url, requestOptions)
    const responseData = await response.json()
    return { response, responseData }
  } catch (error) {
    console.error('Error:', error)
  }
}

export const makeRequestPhoto = async (endPoint, image, user, hash) => {
  // const url = `http://localhost:5001/${endPoint}`
  const url = `https://mech.portwell.com.br/${endPoint}`;
  const formData = new FormData();
  formData.append('Foto', image);
  formData.append('user', JSON.stringify(user));

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: hash,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Resposta do servidor:', responseData);
    return {response, responseData};
  } catch (error) {
    console.error('Erro ao fazer o upload:', error);
  }
}

