import logoPtw from '../assets/images/logo-pwt.png';

export default function saveEtiquetaAsSVG(selectProcess, svgString) {

  const combinedSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="200">
      <rect x="0" y="0" width="100%" height="100%" fill="#FFF" stroke="#000" stroke-width="1" rx="16"/>
      <image href="${logoPtw}" width="150" height="auto" x="10" y="10" />
      <text x="10" y="30%" font-size="10" font-weight="500" fill="#000">${`Modelo: ${selectProcess.equipament}`}</text>
      <text x="10" y="40%" font-size="10" font-weight="500" fill="#000">${`Versão: ${selectProcess.version}`}</text>
      <text x="10" y="50%" font-size="10" font-weight="500" fill="#000">${`Nº de série: ${selectProcess.serial}`}</text>
      ${svgString}
    </svg>
  `;

  const blob = new Blob([combinedSvg], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'etiqueta_qrcode.svg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
