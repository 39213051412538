import React, { useContext, useEffect } from 'react';
import { PageBackground } from '../components/styles';
import Header from '../components/header/Header';
import AppContext from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import AlstonObc from '../components/Forms/AlstonObc';

export default function AddPage({ namePage }) {
  const { user, setUser } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  return (
    <PageBackground>
      <Header title={namePage === "alston" ? "VLI - PC Alston" : "VLI - Mini OBC"} user={user} />
      <AlstonObc namePage={namePage} />
    </PageBackground>
  )
}
