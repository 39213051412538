import React from 'react';
import { StyledTable, StyledTd, StyledTh, StyledTr } from './styles';

export default function BusinessCardTable({ dataArray, columnMapping, selectProcess, setSelectProcess }) {
  if (!dataArray || dataArray.length === 0) {
    return <p>Nenhum dado disponível</p>;
  }

  const columns = Object.keys(columnMapping);

  const handleSelectRow = (item) => {
    setSelectProcess(item);
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <StyledTh key={index}>{columnMapping[column]}</StyledTh>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataArray.map((item, index) => (
          <StyledTr
            key={index}
            onClick={() => handleSelectRow(item)}
            className={selectProcess === item ? 'selected-row' : ''}
          >
            {columns.map((column, columnIndex) => (
              column === 'Ativo' ? (
                item[column] === 0 ? (
                  <StyledTd key={columnIndex} item={item[column]}>Desligado</StyledTd>
                ) : (
                  <StyledTd key={columnIndex} item={item[column]}>Ativo</StyledTd>
                )
              ) : (
                <StyledTd key={columnIndex} item={item[column]}>{item[column]}</StyledTd>
              )
            ))}
          </StyledTr>
        ))}
      </tbody>
    </StyledTable>
  );
}
