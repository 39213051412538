import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Ferramentas from '../pages/Ferramentas';
import NotFound from '../pages/NotFound';
import VLIMontagem from '../pages/VLIMontagem';
import SelectedProcess from '../pages/SelectedProcess';
import SharePage from '../pages/SharePage';
import PcAlston from '../pages/PcAlston';
import GenerateQRCode from '../pages/GenerateQRCode';
import SelectedQrcode from '../pages/SelectedQrcode';
import AddPage from '../pages/AddPage';
import User from '../pages/User';
import SelectUser from '../pages/SelectUser';

const RoutesPages = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/ferramentas" element={<Ferramentas />} />
      <Route path="/vli-montagem" element={<VLIMontagem />} />
      <Route path="/vli-montagem-details/:id" element={<SelectedProcess />} />
      <Route path="/vli-montagem-details/add/:id" element={<AddPage namePage={'miniObc'}/>} />
      <Route path="/MiniOBC" element={<SharePage />} />
      <Route path="/vli-pc-alston" element={<PcAlston />} />
      <Route path="/vli-pc-alston-details/:id" element={<SelectedProcess />} />
      <Route path="/vli-pc-alston-details/add/:id" element={<AddPage namePage={'alston'}/>} />
      <Route path="/PCAlston" element={<SharePage />} />
      <Route path="/gerador-qrcode" element={<GenerateQRCode />} />
      <Route path="/gerador-qrcode-details/:id" element={<SelectedQrcode />} />
      <Route path="/user" element={<User />} />
      <Route path="/user/:id" element={<SelectUser />} />
      <Route path="/user/novo-usuário" element={<SelectUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RoutesPages;
