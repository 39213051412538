import React from 'react';
import { ContainerCard } from './styles';
import { useNavigate } from 'react-router-dom';

export default function CardTools({ title, link}) {
  const navigate = useNavigate()

  return (
    <ContainerCard onClick={() => navigate(`/${link}`)}>
      <h3>{title}</h3>
    </ContainerCard>
  )
}
