import QRCode from 'qrcode-generator';

function generateAndDownloadQRCode(text, cellSize) {
  const qrcode = QRCode(0, 'H');
  qrcode.addData(text || 'Seu texto ou URL aqui');
  qrcode.make();
  const svgTag = qrcode.createSvgTag({ cellSize: cellSize || 4 });

  // Converter SVG para imagem PNG
  const svgBlob = new Blob([svgTag], { type: 'image/svg+xml' });
  const svgURL = URL.createObjectURL(svgBlob);

  const img = new Image();
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    canvas.toBlob((blob) => {
      const a = document.createElement('a');
      a.download = 'qrcode.png'; // Nome do arquivo
      a.href = URL.createObjectURL(blob);
      a.click();
    }, 'image/png');
  };
  img.src = svgURL;
}

export default generateAndDownloadQRCode;
