import React, { useContext, useState } from 'react';
import AppContext from '../../context/AppContext';
import { ButtonBuscar, ButtonsExportContainer, Container, ExportButton, FormContainer, InputForm, LabelForm, SelectForm } from './style';
import exportIcon from '../../assets/icons/file-export-.svg';
import { CSVLink } from 'react-csv';

export default function FindProcess({ setFilteredProcesses }) {
  const [form, setForm] = useState({
    serial: '',
    initial_date: '',
    end_date: '',
    responsible: '',
    status: '',
  });

  const { miniOBCData } = useContext(AppContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  const handleClick = () => {
    const findSerial = miniOBCData.filter((element) => {
      const serialMatch = form.serial === '' || element.serial.includes(form.serial);
      const initialDateMatch = form.initial_date === '' || element.initial_date.includes(form.initial_date);
      const endDateMatch = form.end_date === '' || element.end_date.includes(form.end_date);
      const responsibleMatch = form.responsible === '' || element.responsible.toUpperCase().includes(form.responsible.toUpperCase());
      const statusMatch = form.status === '' || element.status === form.status;

      return serialMatch && initialDateMatch && endDateMatch && responsibleMatch && statusMatch;
    });
    if (findSerial.length > 0) {
      setFilteredProcesses(findSerial);
    } else {
      setFilteredProcesses([]);
    }
  }

  const csvData = miniOBCData.map((item) => ({
    id: item.id,
    serial: item.serial,
    name: item.name,
    describe: item.describe,
    equipament: item.equipament,
    responsible: item.responsible,
    date: item.date,
    status: item.status,
    tasks: item.tasks.length,
  }));

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Serial', key: 'serial' },
    { label: 'Nome', key: 'name' },
    { label: 'Descrição', key: 'describe' },
    { label: 'Equipamento', key: 'equipament' },
    { label: 'Responsável', key: 'responsible' },
    { label: 'Data/Hora', key: 'date' },
    { label: 'Status', key: 'status' },
    { label: 'Numero de Tarefas', key: 'tasks' },
  ];

  return (
    <Container>
      <FormContainer>
        <LabelForm htmlFor="serial">
          Serial
          <InputForm
            type="text"
            name="serial"
            id="serial"
            placeholder="Digite"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="initial_date">
          Data/Hora inicial
          <InputForm
            type="datetime-local"
            name="initial_date"
            id="initial_date"
            placeholder="Selecione a data e hora inicial"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="end_date">
          Data/Hora final
          <InputForm
            type="datetime-local"
            name="end_date"
            id="end_date"
            placeholder="Selecione a data e hora final"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="responsible">
          Responsável
          <InputForm
            type="text"
            name="responsible"
            id="responsible"
            placeholder="Digite"
            onChange={handleChange}
          />
        </LabelForm>
        <LabelForm htmlFor="status">
          Status
          <SelectForm
            name="status"
            id="status"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="" disabled>Selecione</option>
            <option value="Finalizado">Finalizado</option>
            <option value="N.F">N.F</option>
          </SelectForm>
        </LabelForm>
      </FormContainer>
      <ButtonsExportContainer>
        <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
          <ButtonBuscar onClick={() => handleClick()}>Buscar</ButtonBuscar>
        </div>
        <ExportButton>
          <CSVLink data={csvData} headers={csvHeaders} filename="VLIMontagem.csv" >
            <img src={exportIcon} alt="exportIcon" />
          </CSVLink>
        </ExportButton>
      </ButtonsExportContainer>
    </Container>
  )
}
