import React from 'react';
import { ContainerManager } from './styles';
import CardTools from './CardTools';

export default function Manager({ user }) {
  return (
    <ContainerManager>
      {user.permissao['MiniOBC'] === 1 && <CardTools title="Montagem VLI Mini OBC" link="vli-montagem"/>}
      {user.permissao['PcAlston'] === 1 && <CardTools title="Montagem VLI PC Alston" link="vli-pc-alston"/>}
      {user.permissao['QrCode'] === 1 && <CardTools title="Gerador QRCode" link="gerador-qrcode"/>}
      {user.permissao['RH'] === 1 && <CardTools title="Recursos Humanos" link="User"/>}
    </ContainerManager>
  )
}
