import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/header/Header';
import AppContext from '../context/AppContext';
import { DefautButton, PageBackground } from '../components/styles';
import { FindSerialBackground, ProcessContainer } from '../components/Forms/style';
import { useNavigate } from 'react-router-dom';
import backIcon from '../assets/icons/rewind-backcom.svg';
import addIcon from '../assets/icons/add.svg';
import editIcon from '../assets/icons/edit.svg';
import removeIcon from '../assets/icons/trash.svg';
import { ButtonsContainer, CRUDContainer } from '../components/ToolsManager/styles';
import GenerateTable from '../components/Table/GenerateTable';
import FindQrcode from '../components/Forms/FindQrcode';

export default function GenerateQRCode() {

  const { user, setUser, selectQrcode, setSelectQrcode, qrcodeList } = useContext(AppContext);
  const navigate = useNavigate();

  const [filteredQrcode, setFilteredQrcode] = useState([]);

  const columnMapping = {
    name: 'Nome',
    date: 'Data/Hora',
    link: 'Link'
  };

  const handleEdit = () => {
    if (Object.keys(selectQrcode).length > 0) navigate(`/gerador-qrcode-details/${selectQrcode.id}`);
  }

  const handleAdd = () => {
    setSelectQrcode({});
    navigate(`/gerador-qrcode-details/${qrcodeList[(qrcodeList.length - 1)].id + 1}`);
  }

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    setFilteredQrcode(qrcodeList);
  }, [qrcodeList]);

  useEffect(() => {
    if (!user || user.permissao.QrCode !== 1) navigate('/ferramentas')
  }, [navigate, user])

  return (
    <PageBackground>
      <Header title="Gerador QRCode" user={user} />
      <FindSerialBackground>
        <ButtonsContainer>
          <DefautButton onClick={() => navigate('/ferramentas')}>
            <img src={backIcon} alt="backIcon" />
          </DefautButton>
          <CRUDContainer>
            <DefautButton onClick={handleAdd}>
              <img src={addIcon} alt="addIcon" />
            </DefautButton>
            <DefautButton onClick={handleEdit}>
              <img src={editIcon} alt="editIcon" />
            </DefautButton>
            <DefautButton>
              <img src={removeIcon} alt="removeIcon" />
            </DefautButton>
          </CRUDContainer>
        </ButtonsContainer>
        <FindQrcode setFilteredProcesses={setFilteredQrcode} />
        <ProcessContainer>
          <GenerateTable
            dataArray={filteredQrcode}
            columnMapping={columnMapping}
            selectProcess={selectQrcode}
            setSelectProcess={setSelectQrcode}
          />
        </ProcessContainer>
      </FindSerialBackground>
    </PageBackground>
  );
}
