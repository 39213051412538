import React, { useContext, useEffect, useState } from 'react';
import backIcon from '../../assets/icons/rewind-backcom.svg';
import { Button, ButtonsContainer, Container, FormContainer, FormInputs, Input, Label, QRCode, QRCodeContainer } from './styles';
import { DefautButton } from '../styles';
import { useNavigate } from 'react-router-dom';
import QRCodeGenerator from '../../utils/QRCodeGenerator';
import AppContext from '../../context/AppContext';
import generateAndDownloadQRCode from '../../utils/downloadQRCode';

export default function DetailsQrcode({ selectQrcode }) {
  const [form, setForm] = useState({});
  const [generate, setGenerate] = useState(false);

  const { user } = useContext(AppContext);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }  

  const handleGenerate = () => {
    if (form.name && form.link) setGenerate(true);
    else setGenerate(false)
  }

  const handleExport = () => {
    handleGenerate();
    if (generate && form.link) {
      generateAndDownloadQRCode(`${form.link}`, 4);
    }  
  }

  useEffect(() => {
    if (Object.keys(selectQrcode).length > 0) {
      setForm(selectQrcode);
    }
  }, [selectQrcode]);  

  useEffect(() => {
    if (user.permissao.QrCode !== 1) navigate('/ferramentas')
  }, [navigate, user.permissao.QrCode]);

  return (
    <Container>
      <DefautButton style={{ justifyContent: 'start'}} onClick={() => navigate('/gerador-qrcode')}>
        <img src={backIcon} alt="backIcon" />
      </DefautButton>
      <FormContainer>
        <FormInputs>
          <Label htmlFor="name">
            Nome:
            <Input
              type="text"
              id="name"
              name="name"
              value={form.name || ''}
              onChange={handleChange}
            />
          </Label>
          <Label htmlFor="link">
            Link:
            <Input
              type="text"
              id="link"
              name="link"
              value={form.link || ''}
              onChange={handleChange}
            />
          </Label>
          <Label htmlFor="acessos">
            Acessos:
            <Input
              type="text"
              id="acessos"
              name="acessos"
              disabled
              value={form.acessos || ''}
              onChange={handleChange}
            />
          </Label>
        </FormInputs>
        <QRCodeContainer>
          <ButtonsContainer>
            <Button onClick={handleGenerate}>
              Gerar QRCode
            </Button>
            <Button onClick={handleExport}>
              Exportar QRCode
            </Button>
          </ButtonsContainer>
          <QRCode>
            {generate && (
              <QRCodeGenerator text={`${form.link}`} />
            )}
          </QRCode>
        </QRCodeContainer>
      </FormContainer>
    </Container>
  )
}
