import styled from 'styled-components'

export const ContainerManager = styled.div`
  width: 100%;
  max-width: 772px;
  height: 80%;
  margin-top: 80px;
  padding: 66px;
  gap: 57px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  overflow: auto;

  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);

  background-color: white;
`

export const ContainerCard = styled.button`
  width: 290px;
  height: 237px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  padding: 30px;

  border-radius: 16px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: -4px -4px 19px 0px #4C0074 inset;
  cursor: pointer;

  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


  /* @media (min-width: 769px) {
    width: 290px;
    height: 237px;
  }

  @media (max-width: 768px) {
    width: 290px;
    height: 237px;
  } */
`

export const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
  }
`

export const CRUDContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 32px;
`