import React, { useContext, useState } from 'react';
import eye from '../../assets/icons/eye.svg';
import eyeOff from '../../assets/icons/eye-off.svg';
import ptwLogo from '../../assets/images/logo-pwt.png';
import { DefaultButtonOk, DefaultImg, DefautButton, WarningError } from '../styles';
import { ContainerForm, Input, Label } from './style';
// import { users } from '../../mocks/users';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import { makeRequest } from '../../utils/fetchDb';

export default function LoginForm() {
  const [login, setLogin] = useState({ user: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [wrongLogin, setWrongLogin] = useState(false);

  const { setUser, setIsLogged, setHash } = useContext(AppContext);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLogin({
      ...login,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evita o envio padrão do formulário

    // Criando um objeto hash com o algoritmo SHA-1
    const hash = new window.TextEncoder().encode(`${login.user}${login.password}`);
    const hashBuffer = await window.crypto.subtle.digest('SHA-1', hash);

    // Convertendo o resultado para uma representação hexadecimal
    const hashedData = Array.from(new Uint8Array(hashBuffer))
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

    setHash(hashedData);

    const Login = await makeRequest('POST', 'Login', {
      HashCode: hashedData
    })
    if (Login.response.status === 200) {
      const {responseData} = await makeRequest('GET', `User?User=${login.user}`, null, hashedData);
      setWrongLogin(false);
      setUser({user: login.user, permissao: responseData['Allowed']});
      localStorage.setItem('login', JSON.stringify({user: login.user, permissao: responseData['Allowed']}));
      setIsLogged(true);
      return navigate("/ferramentas")
    }
    return setWrongLogin(true)
  }

  return (
    <ContainerForm onSubmit={handleSubmit}>
      <img src={ptwLogo} alt="ptwLogo" />
      <Label htmlFor="user" width="300px">
        <Input
          type="text"
          placeholder="Usuário"
          name="user"
          value={login.user}
          onChange={handleChange}
        />
      </Label>
      <Label htmlFor="password" width="300px">
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Senha"
          name="password"
          value={login.password}
          onChange={handleChange}
        />
        <DefautButton type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? (
            <DefaultImg src={eyeOff} alt="eyeOff" width="30px" />
          ) : (
            <DefaultImg src={eye} alt="eye" width="30px" />
          )}
        </DefautButton>
      </Label>
      {wrongLogin && <WarningError>Usuário e/ou senha incorretos!</WarningError>}
      <DefaultButtonOk
        type="submit"
        width="100px"
        height="40px"
        color="#0AA696"
        onClick={handleSubmit}
      >
        Entrar
      </DefaultButtonOk>
    </ContainerForm>
  );
}
