import { BrowserRouter } from 'react-router-dom';
import RoutesPages from './routes/RoutesPages';
import AppProvider from './provider/AppProvider';

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <RoutesPages />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
