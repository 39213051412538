import styled from 'styled-components'

export const ContainerBackground = styled.div`
  width: 100vw;
  max-width: 772px;

  margin-top: 80px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  border: 1px solid #000;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  background-color: #fff;

  gap: 16px;

  overflow: auto;

  @media (min-width: 769px) {
    height: calc(100% - 80px);
    padding: 24px 33px;
  }

  @media (max-width: 768px) {
    height: calc(100% - 50px);
    padding: 24px 0px;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 769px) {
  }

  @media (max-width: 768px) {
    padding-left: 33px;
  }
`

export const Title = styled.h3`
  width: 100%;

  color: #000;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-align: center;

  @media (min-width: 769px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 26px;
  }
`

export const DetailsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 28px;
  
  @media (min-width: 769px) {
    padding: 54px;
  }

  @media (max-width: 768px) {
    padding: 54px 14px;
  }
`

export const DetailsLabel = styled.label`
  width: 100%;

  display: flex;
  align-items: center;

  color: #000;
  font-style: normal;
  line-height: normal;

  h4 {
    font-weight: bold;
  }

  p {
    width: 100%;
    font-weight: 400;

    text-align: right;
  }

  @media (min-width: 769px) {
    justify-content: space-between;

    font-size: 28px;
    h4 {
      max-width: 250px;
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;

    font-size: 20px;
  }
`
