import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 772px;

  margin-top: 80px;

  display: flex;
  flex-direction: column;

  padding: 24px 33px;
  gap: 7px;

  border-radius: 28px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 769px) {
    height: 364px;
  }

  @media (max-width: 768px) {
  }
`

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 10px;
  }
`

export const FormInputs = styled.div`
  @media (min-width: 769px) {
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    gap: 24px;
  }

  @media (max-width: 768px) {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 24px;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;

  @media (min-width: 769px) {
    
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Input = styled.input`
  width: 307px;
  height: 28px;
  flex-shrink: 0;

  padding: 0 15px;

  border-radius: 8px;
  border: none;
  background: #D9D9D9;

  font-weight: bold;

  @media (min-width: 769px) {
    
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 35px;

  @media (min-width: 769px) {
    width: 50%;

    justify-content: space-around;
  }

  @media (max-width: 768px) {
    width: 100%;

    justify-content: space-around;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 23px;
`

export const Button = styled.button`
  width: 87px;
  height: 55px;

  display: flex;
  padding: 9px 13px 8px 13px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  cursor: pointer;
`

export const QRCode = styled.div`
  width: 208px;
  height: 208px;

  display: flex;
  align-items: center;
  justify-content: center;
`
