import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/header/Header';
import AppContext from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { PageBackground } from '../components/styles';
import DetailsProcess from '../components/DetailsProcess/DetailsProcess';

export default function ProcessDetails() {
  const [params, setParams] = useState({
    title: '',
    titleDetails: '',
    link: ''
  })
  const { user, setUser, selectProcess } = useContext(AppContext);
  const navigate = useNavigate();

  const columnMapping = {
    id: 'Item',
    name: 'Tarefa',
    date: 'Data/Hora',
    checked: 'Status'
  }

  const pathSegments = window.location.pathname.split('/');
  const type = pathSegments[1];

  useEffect(() => {
    if (type === 'vli-montagem-details') {
      setParams({title: 'VLI - MINI OBC', titleDetails: 'Mini OBC', link: 'MiniOBC'});
    } else if (type === 'vli-pc-alston-details') {
      setParams({title: 'VLI - PC Alston', titleDetails: 'PC Alston', link: 'PCAlston'})
    }
  }, [type])

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    if (!selectProcess || Object.keys(selectProcess).length === 0) {
      navigate('/ferramentas');
    }
  }, [navigate, selectProcess]);

  return (
    <PageBackground>
      <Header title={params.title} user={user} />
      <DetailsProcess isShare={false} columnMapping={columnMapping} title={params.titleDetails} type={type} />
    </PageBackground>
  );
}
