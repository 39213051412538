import React from 'react';
import { ContainerBackground, DetailsContainer, DetailsLabel, HeaderContainer, Title } from './styles';
import { StyledTable, StyledTd, StyledTh, StyledTr } from '../Table/styles';
import QRCodeGenerator from '../../utils/QRCodeGenerator';

export default function ShareProcess({ columnMapping, selectProcess, title, link }) {
  return (
    <ContainerBackground>
      {selectProcess.length > 0 ? (
        <>
          <HeaderContainer>
            <Title>{`Montagem ${title}`}</Title>
          </HeaderContainer>
          <DetailsContainer>
            <DetailsLabel>
              <h4>Equipamento:</h4>
              <p>{selectProcess[0].equipament}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Nº de Série:</h4>
              <p>{selectProcess[0].serial}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Versão:</h4>
              <p>{selectProcess[0].version}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>Técnico Responsável pela inspeção:</h4>
              <p>{selectProcess[0].responsible}</p>
            </DetailsLabel>
            <DetailsLabel>
              <h4>QR-Code:</h4>
              {/* <QRCodeGenerator text={`https://www.service.portwell.com.br/${link}?SN=${selectProcess.serial}`} /> */}
              <QRCodeGenerator text={`http://localhost:3000/${link}?SN=${selectProcess.serial}`} />
            </DetailsLabel>
          </DetailsContainer>
          {selectProcess[0].tasks && (
            <StyledTable>
              <thead>
                <tr>
                  {Object.keys(columnMapping).map((column, index) => (
                    <StyledTh key={index}>{columnMapping[column]}</StyledTh>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectProcess[0].tasks.map((item, rowIndex) => (
                  <StyledTr key={rowIndex}>
                    {Object.keys(columnMapping).map((column, columnIndex) => (
                      <StyledTd key={columnIndex}>
                          {item[column]}
                      </StyledTd>
                    ))}
                  </StyledTr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </>
      ) : (
        <p>Nenhum processo selecionado</p>
      )}
    </ContainerBackground>
  );
}
