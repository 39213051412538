import React from 'react'
import LoginForm from '../components/Forms/LoginForm'
import { ContainerLogin } from '../components/styles'

export default function Login() {
  return (
    <ContainerLogin>
      <LoginForm />
    </ContainerLogin>
  )
}
