import React, { useContext, useEffect } from 'react'
import Header from '../components/header/Header'
import { PageBackground } from '../components/styles'
import { useNavigate } from 'react-router-dom';
import AppContext from '../context/AppContext';
import UserCard from '../components/Forms/UserCard';

export default function User() {
  const { user, setUser } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      setUser(JSON.parse(login));
    } else {
      navigate('/');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    if (!user || user.permissao.RH !== 1) navigate('/ferramentas')
  }, [navigate, user])

  return (
    <PageBackground>
      <Header title={`Colaboradores`} user={user} />
      <UserCard />
    </PageBackground>
  )
}
