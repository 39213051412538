import React, { Component } from 'react';
import QRCode from 'qrcode-generator';

class QRCodeGenerator extends Component {
  constructor(props) {
    super(props);
    this.qrcodeContainer = React.createRef();
  }

  componentDidMount() {
    this.generateQRCode();
  }

  componentDidUpdate() {
    this.generateQRCode();
  }

  generateQRCode() {
    const { text, cellSize } = this.props;

    const qrcode = QRCode(0, 'H');
    qrcode.addData(text || 'Seu texto ou URL aqui');
    qrcode.make();
    this.qrcodeContainer.current.innerHTML = qrcode.createSvgTag({ cellSize: cellSize || 4 });
  }

  render() {
    return (
      <div>
        <div ref={this.qrcodeContainer}></div>
      </div>
    );
  }
}

export default QRCodeGenerator;
